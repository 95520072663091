<template>
	<div class="">
		<InPageImage></InPageImage>
		<div class="wrapper bg-white baseContent">
			<Breadcrumb :crumbsList="pathList"></Breadcrumb>
			<div class="base-font-40 text-center padding-tb-20 margin-top-20">{{wzContent.title}}</div>
			<div class="newsLable flex align-center justify-center text-888 margin-bottom-30">
				<span>文章出处：{{wzContent.source}}</span>
				<span>阅读量：{{wzContent.perusal?wzContent.perusal:0}}</span>
				<span>发表时间：{{wzContent.createTimeStr}}</span>
			</div>
			<div v-if="wzContent.depict" class="newsAbstract text-left text-666  margin-bottom-20">
        {{wzContent.depict}}
			</div>
			<div v-html="wzContent.content" class="newsContent text-left base-font-28">
      </div>
			
		</div>
	</div>
</template>

<script>
	import Breadcrumb from '../../components/breadcrumbs.vue'
	import InPageImage from '../../components/inPageImage.vue'
  import {getWzContentDetail, menuListById} from "@/api/api";
	export default{
		components: {
			Breadcrumb,
			InPageImage
		},
		data(){
			return{
        menuId:'',
        id:'',
        pathList: [],
        curMenu: {},
        wzContent:{},
			}
		},
    mounted() {
      this.menuId = this.$route.query.menuId;
      this.id = this.$route.query.id;
      this.menuListById();
      this.getWzContentDetail();
    },
		methods:{
      menuListById() {
        menuListById(this.menuId).then(res => {
          this.pathList = res.data.pathList;
          this.pathList.push({name:res.data.curMenu.name+'详情',path:''});
          this.curMenu = res.data.curMenu;
        })
      },
      getWzContentDetail() {
        getWzContentDetail(this.id).then(res => {
          this.wzContent = res.data;
          this.wzContent.createTimeStr = this.$moment(res.data.clickTime).format('YYYY-MM-DD');
        })
      },
		}
	}
</script>

<style>
.newsLable span{ padding:0 20px;}
.newsLable span+span{border-left:1px solid #dddddd;line-height:1}
.newsAbstract{background:#f0f5f8;padding:25px 30px;box-sizing: border-box;line-height:2.1;}
.newsContent {line-height:2.1;padding:0 0 50px 0;min-height:30vh;}
</style>